export enum Environment {
    Local = 'local',
    Int = 'int',
    Qa = 'qa',
    Con1 = 'con1',
    Con2 = 'con2',
    Con3 = 'con3',
    Con4 = 'con4',
    Prod = 'prod',
}

export const getEnvironment = (): Environment => {
    const {
        location: { hostname },
    } = window
    if (hostname.includes('int.')) {
        return Environment.Int
    } else if (hostname.includes('qa.')) {
        return Environment.Qa
    } else if (hostname.includes('con1.')) {
        return Environment.Con1
    } else if (hostname.includes('con2.')) {
        return Environment.Con2
    } else if (hostname.includes('con3.')) {
        return Environment.Con3
    } else if (hostname.includes('con4.')) {
        return Environment.Con4
    } else if (
        hostname === 'www.igs.com' ||
        hostname === 'account.scanaenergy.com' ||
        hostname === 'webpledge.scanaenergy.com' ||
        hostname === 'secure.scanaenergy.com'
    ) {
        return Environment.Prod
    }
    return Environment.Local
}

export const getCacheBusterValue = (): string => {
    const date = new Date()
    const middleOfMonth = 15
    return date.getMonth() + (date.getDate() > middleOfMonth ? '-first' : '-second')
}

export const getApiUrl = (env: Environment): string => {
    if (env === Environment.Local) {
        return 'https://localhost:7265'
    }

    if (env === Environment.Prod) return `https://webpledge-api.scanaenergy.com`

    return `https://website-api-scana-web-pledge.${env}.igs.com`
}

export const getCdnUrl = (env: Environment): string => {
    return env === Environment.Local ? '/' : `https://cdn.igs.com/${env}-public-assets/scana/`
}
